@import "./variables.scss";

.footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

@media (min-width: $md) {
    .footer {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

.footer .footer-headline {
    font-weight: bold;
}

.footer-copyright {
    line-height: 1;
}

.footer-logo {
    height: 2.5rem;
    max-width: 100%;
}

.footer a {
    text-decoration: none;
    color: inherit;
}

.footer .too-fresh-logo img {
    height: 4rem;
}