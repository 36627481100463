.footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (width >= 768px) {
  .footer {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.footer .footer-headline {
  font-weight: bold;
}

.footer-copyright {
  line-height: 1;
}

.footer-logo {
  max-width: 100%;
  height: 2.5rem;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer .too-fresh-logo img {
  height: 4rem;
}
/*# sourceMappingURL=index.42a53ee5.css.map */
